import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		padding: 0,
	},
	title: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
}));

export default function Nav() {
	const classes = useStyles();

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			"#projects-anchor"
		);

		if (anchor) {
			anchor.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<AppBar position="static" color="default" elevation={0}>
			<Container>
				<Toolbar className={classes.toolbar}>
					<Typography variant="h6">JP</Typography>
					<Typography className={classes.title}>Jenesh Pradhanang</Typography>
					<Button
						size="small"
						color="primary"
						variant="contained"
						onClick={handleClick}
					>
						Projects
					</Button>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

import { Button, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Profile from "../img/profile.jpg";

const useStyles = makeStyles((theme) => ({
	img: {
		width: "100%",
		height: theme.spacing(50),
		margin: "0 auto",
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(40),
			height: theme.spacing(50),
		},
	},
	aboutDiv: {
		flex: 1,
		textAlign: "center",
		[theme.breakpoints.up("md")]: {
			textAlign: "left",
		},
	},
	textDiv: {
		textAlign: "center",
		[theme.breakpoints.up("md")]: {
			textAlign: "left",
		},
	},
	chipDiv: {
		padding: theme.spacing(3, 0),
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	chip: {
		borderRadius: 8,
		borderColor: theme.palette.divider,
	},
	contactBtn: {
		marginTop: theme.spacing(2),
	},
}));

export default function Hero() {
	const classes = useStyles();
	return (
		<div>
			<Box py={6}>
				<Grid container justify="center" spacing={4} alignItems="center">
					<Grid item xs={12} md={"auto"}>
						<Avatar
							variant="rounded"
							alt="Profile"
							src={Profile}
							className={classes.img}
						/>
					</Grid>
					<Grid item xs={12} md={"auto"} className={classes.aboutDiv}>
						<Typography variant="h2" gutterBottom>
							Hello!
						</Typography>
						<Typography variant="h5" color="textSecondary" gutterBottom>
							Filled with a passion for designing convincing products, I have a
							deep desire to excel and continuously improve my work with joy.
							And always up for a challenge.
						</Typography>
						<div className={classes.chipDiv}>
							{[
								"HTML/CSS",
								"JAVASCRIPT",
								"REACT",
								"REACT NATIVE",
								"MATERIAL UI",
								"ANT DESIGN",
								"BOOTSTRAP",
								"TAILWIND",
								"PHP",
								"LARAVEL",
							].map((item) => (
								<Chip
									key={item}
									variant="outlined"
									color="primary"
									label={item}
									className={classes.chip}
								/>
							))}
						</div>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}

import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
	props: {
		MuiButton: {
			disableElevation: true,
		},
	},
	palette: {
		primary: {
			main: "#282424",
		},
	},
	shape: {
		borderRadius: 8,
	},
	typography: {
		fontFamily: "'Poppins', sans-serif",
	},
});

export default function Root({ children }) {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

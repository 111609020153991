import Container from "@material-ui/core/Container";

import Hero from "./components/Hero";
import Nav from "./components/Nav";
import Projects from "./components/Projects";

export default function App() {
	return (
		<div>
			<Nav />
			<Container fixed>
				<Hero />
				<Projects />
			</Container>
		</div>
	);
}

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Masonry from "react-masonry-css";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import QuizyImage from "../img/quizy.png";
import KhataImage from "../img/khata.png";
import GenshinImage from "../img/genshin.png";
import CovidImage from "../img/covid.png";
import SigmaImage from "../img/sigma.png";
import SnsImage from "../img/sns.png";
import AstroImage from "../img/astro.png";

const projects = [
	{
		key: "_1",
		title: "Quizy",
		description:
			"Quizy is a platform/application for playing quiz in a group. User can create categories, questions and quizzes. Play quiz in a group. Pass question and have fun with audience.",
		link: "https://quizy.jenesh.com.np",
		img: QuizyImage,
	},
	{
		key: "_2",
		title: "Genshin fan web",
		description:
			"Website for displaying characters and weapons from the game Genshin impact. List of characters and weapons with details.",
		link: "https://genshinfanweb.netlify.app/characters",
		img: GenshinImage,
	},
	{
		key: "_3",
		title: "Khata",
		description: "A simple bootstrap UI for a grocery management system.",
		link: "https://khata.netlify.app/",
		img: KhataImage,
	},
	{
		key: "_4",
		title: "Covid 19 tracker",
		description:
			"All the data related to covid 19 cases in Nepal. Contains cases, hospitals and frequently asked questions related to covid.",
		link: "https://covid-19jdata.netlify.app/",
		img: CovidImage,
	},
	{
		key: "_5",
		title: "Sigma Auto Nepal",
		description: "Ecommerce site for auto parts.",
		link: "https://sigmaautonepal.com/",
		img: SigmaImage,
	},
	{
		key: "_6",
		title: "Srijansil Nepali Samaj",
		description: "Website and CMS",
		link: "https://snscanada.ca/home",
		img: SnsImage,
	},
	{
		key: "_7",
		title: "Urbi Astrologers",
		description: "Urbi Astrologers for Astrologers.",
		link: "https://astrourbi.com/",
		img: AstroImage,
	},
];

const breakpointColumnsObj = {
	default: 4,
	1280: 3,
	960: 2,
	600: 1,
};

export default function Projects() {
	return (
		<Box py={6} id="projects-anchor">
			<Grid container spacing={4} justify="center">
				<Grid item xs={12}>
					<Typography variant="h3" gutterBottom align="center">
						Projects
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column"
					>
						{projects.map(({ key, title, description, link, img }) => (
							<Card key={key}>
								<CardHeader title={title} />
								<CardMedia
									style={{
										height: 100,
										width: "100%",
									}}
									image={img}
									title={title}
								/>
								<CardContent>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p"
									>
										{description}
									</Typography>
								</CardContent>
								<CardActions>
									<Button
										component="a"
										href={link}
										target="_blank"
										size="small"
										color="primary"
										variant="contained"
										style={{ marginLeft: "auto" }}
									>
										Visit
									</Button>
								</CardActions>
							</Card>
						))}
					</Masonry>
				</Grid>
			</Grid>
		</Box>
	);
}
